import { Image, Box, Badge, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@chakra-ui/react';
import { CollectibleForToken } from '~/types';

const TokenCollectible = ({
    tokenCollectible,
}: {
    tokenCollectible: CollectibleForToken;
}) => {
    return (
        <Popover>
            <PopoverTrigger>
                <Box
                    bg="white"
                    padding={1}
                    borderRadius={5}
                    position="relative"
                >
                    <Image
                        src={tokenCollectible.image_url}
                        w="100%"
                        borderRadius={4}
                    />
                    <Badge
                        fontWeight="semibold"
                        letterSpacing="widest"
                        position="absolute"
                        top={2}
                        right={2}
                    >
                        x{tokenCollectible.quantity}
                    </Badge>
                </Box>
            </PopoverTrigger>
            <PopoverContent w="auto">
                <PopoverArrow />
                <PopoverBody>
                    {tokenCollectible.name}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default TokenCollectible;
