import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import React from 'react';

function RoundedBox({ children, ...rest }: BoxProps) {
  return (
    <Box
      borderRadius="50px"
      backgroundColor="brand.100"
      border="2px solid black"
      width={{ base: '98vw', sm: '90vw', md: '80vw', lg: '70vw' }}
      overflow="hidden"
      mx="auto"
      {...rest}
    >
      <Box
        borderRadius="50px"
        border="2px solid black"
        margin="0 0 0 auto"
        transform="translate(5px, -5px)"
        overflow="hidden"
      >
        {children}
      </Box>
    </Box>
  );
}

export default RoundedBox;
