import {
  Badge,
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
} from "@chakra-ui/react";
import { readContract } from "wagmi/actions";
import { NextSeo } from "next-seo";
import React, { useMemo } from "react";
import BoldText from "~/lib/components/BoldText";
import ButtonShadow from "~/lib/components/ButtonShadow";
import RoundedBox from "~/lib/components/RoundedBox";
import Layout from "~/lib/layout";
import { abi } from "~/assets/nft-abi";
import {
  CollectibleForToken,
  TokenCollectibleData,
  TokenMetadata,
} from "~/types";
import { ethers } from "ethers";
import config from "~/lib/config";
import TokenCollectible from "~/lib/pages/home/TokenCollectible";

const TokenAttributes = ({
  metadata,
  isLoading = true,
  isStaked,
}: {
  metadata: TokenMetadata | undefined;
  isLoading: boolean;
  isStaked: boolean;
}) => {
  const phase = useMemo(() => {
    if (metadata?.image.includes("phase-0")) return "Unrevealed";
    if (metadata?.image.includes("phase-1")) return "Baby HeeDong";
    if (metadata?.image.includes("phase-2")) return "Adult HeeDong";
  }, [metadata]);
  const isClaimed = useMemo(() => {
    return metadata?.attributes.find(
      (attribute) =>
        attribute.trait_type === "Blindbox" && attribute.value === "Claimed"
    )
      ? true
      : false;
  }, [metadata]);

  return metadata && !isLoading ? (
    <Flex flexDirection="column">
      <Flex mt="10px" justifyContent="center">
        <Badge
          bgColor={isStaked ? "teal" : "brand.100"}
          fontSize={{ base: "xs", md: "sm" }}
          mx={1}
          borderRadius="500px"
          px={3}
          py={1}
          color="white"
        >
          {isStaked ? "Staked" : "Unstaked"}
        </Badge>
        <Badge
          bgColor={isClaimed ? "red" : "green"}
          fontSize={{ base: "xs", md: "sm" }}
          mx={1}
          borderRadius="500px"
          px={3}
          py={1}
          color="white"
        >
          {isClaimed ? "Blindbox Claimed" : "Blindbox Unclaimed"}
        </Badge>
      </Flex>
      <Flex mt="10px" justifyContent="center">
        {phase && (
          <Badge
            bgColor="#39A6D9"
            fontSize={{ base: "xs", md: "sm" }}
            mx={1}
            borderRadius="500px"
            px={3}
            py={1}
            color="white"
          >
            {phase}
          </Badge>
        )}
      </Flex>
    </Flex>
  ) : null;
};

const TokenImage = ({ metadata }: { metadata: TokenMetadata | undefined }) => {
  return (
    <Image
      src={
        metadata?.image ??
        "https://i.seadn.io/gcs/files/d0896b9ac1fdc50e012b5adc97d4ec55.gif?auto=format&w=1920"
      }
      w={{ base: "200px", md: "300px" }}
      h={{ base: "200px", md: "300px" }}
      borderRadius={4}
      border="8px solid white"
      my={3}
    />
  );
};

export default function HeeDong() {
  const [tokenId, setTokenId] = React.useState("");
  const [metadata, setMetadata] = React.useState<TokenMetadata | undefined>(
    undefined
  );
  const [tokenCollectibles, setTokenCollectibles] = React.useState<
    CollectibleForToken[] | undefined
  >(undefined);
  const [isStaked, setIsStaked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const queryHandler = async () => {
    setIsLoading(true);
    try {
      if (+tokenId > 5555) throw new Error("Token ID is too high");
      if (+tokenId < 1) throw new Error("Token ID invalid");
      const data = await readContract({
        address: "0x52352040b5262d64973b004d031bc041720aa434",
        abi,
        functionName: "tokenStakeStatus",
        args: [+tokenId],
      });
      setIsStaked((data as ethers.BigNumber).toNumber() > 0);
      const metadata: TokenMetadata = await (
        await fetch(`${config.baseUrl}/nft-metadata/heedong/${tokenId}`)
      ).json();
      setMetadata(metadata);

      const tokenCollectiblesData: TokenCollectibleData = await (
        await fetch(`${config.baseUrl}/token-collectibles?tokenIds=${tokenId}`)
      ).json();

      const tokenCollectibles: CollectibleForToken[] = Object.values(
        tokenCollectiblesData.token_collectibles
      )
        .map((collectible) => ({
          ...collectible,
          quantity:
            tokenCollectiblesData.token_collectibles_for_tokens[tokenId]?.[
              `${collectible.id}`
            ]?.quantity || 0,
        }))
        .filter(({ quantity }) => quantity !== 0);

      setTokenCollectibles(tokenCollectibles);
    } catch (err) {
      alert((err as Error).message);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <Layout>
        <NextSeo
          title="HeeDong Token Checker"
          description="Is your HeeDong token staked? Check here"
          openGraph={{
            title: "HeeDong Token Checker",
            description: "Is your HeeDong token staked? Check here",
            images: [
              {
                url: "https://i.seadn.io/gcs/files/d0896b9ac1fdc50e012b5adc97d4ec55.gif?auto=format&w=1920",
                alt: "HeeDong",
              },
            ],
            siteName: "Meet HeeDong",
          }}
        />
        <Box pt={{ md: "10vh", base: "10vh" }}>
          <RoundedBox>
            <Box>
              <Box bgColor="brand.300" py={8}>
                <BoldText fontSize={{ base: "2xl", md: "5xl" }}>
                  HeeDong token checker
                </BoldText>
              </Box>
              <Box bgColor={"brand.200"} py={3}>
                <Flex alignItems={"center"} flexDir="column" py={3}>
                  <Flex alignItems={"center"} mb={3}>
                    <Input
                      w={{ md: "180px", base: "150px" }}
                      mt={3}
                      placeholder={"Token ID"}
                      _placeholder={{ color: "#706f6f" }}
                      borderRadius={0}
                      fontSize={{ md: "3xl", base: "xl" }}
                      h={{ base: "30px", md: "50px" }}
                      borderBottom={"1px solid black"}
                      value={tokenId}
                      onChange={(event) => setTokenId(event.target.value)}
                      textAlign="center"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") queryHandler();
                      }}
                      mx={3}
                      color="black"
                    />
                    <ButtonShadow
                      onClick={queryHandler}
                      mt={3}
                      isLoading={isLoading}
                      fontSize={{ base: "md", md: "xl" }}
                      color="black"
                    >
                      Check
                    </ButtonShadow>
                  </Flex>

                  <TokenAttributes
                    metadata={metadata}
                    isLoading={isLoading}
                    isStaked={isStaked}
                  />

                  <TokenImage metadata={metadata} />
                  {tokenCollectibles && (
                    <Grid
                      templateColumns={[
                        "repeat(1, 1fr)",
                        "repeat(2, 1fr)",
                        "repeat(3, 1fr)",
                      ]}
                      gap={2}
                      w={{ base: "200px", md: "300px" }}
                    >
                      {tokenCollectibles.map((tokenCollectible) => (
                        <GridItem w="w-full" key={tokenCollectible.id}>
                          <TokenCollectible
                            tokenCollectible={tokenCollectible}
                          />
                        </GridItem>
                      ))}
                    </Grid>
                  )}
                </Flex>
              </Box>
            </Box>
          </RoundedBox>
        </Box>
      </Layout>
    </div>
  );
}
