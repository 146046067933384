import { Button } from '@chakra-ui/react';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ButtonShadow({ children, ...attr }: any) {
  const backgroundColor = attr.disabled ? 'gray.500' : 'brand.100';
  const activeBackgroundColor = attr.disabled ? 'gray.500' : 'white';
  const hoverTransform = attr.disabled ? 'none' : 'translate3d(1px, 1px, 1px)';
  const activeTransform = attr.disabled ? 'none' : 'translate3d(3px, 3px, 3px)';
  const hoverBoxShadow = attr.disabled ? '0 5px black' : '0 2px black';
  const activeBoxShadow = attr.disabled ? '0 5px black' : 'none';

  return (
    <Button
      textTransform="uppercase"
      margin="0 auto"
      py="5px"
      fontSize="xl"
      height="100%"
      // display="block"
      border="2px solid black"
      boxShadow="0 5px black"
      backgroundColor={backgroundColor}
      _hover={{
        backgroundColor,
        transform: hoverTransform,
        boxShadow: hoverBoxShadow,
      }}
      _active={{
        backgroundColor: activeBackgroundColor,
        transform: activeTransform,
        boxShadow: activeBoxShadow,
      }}
      {...attr}
      disabled
    >
      {children}
    </Button>
  );
}
